export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/profile',
    name: 'profile-view',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      resource: 'membership',
      action: 'manage',
    },
  },
]
