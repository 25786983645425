import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('user') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const isEmailVerified = () => localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).email_verified_at

export const getUserData = () => JSON.parse(localStorage.getItem('user'))

export const getUserPermissions = () => JSON.parse(localStorage.getItem('permissions'))

export const getHomeRouteForLoggedInUser = permission => {
  if (permission === 'membership manage') return { name: 'dashboard' }
  return { name: 'auth-login' }
}
